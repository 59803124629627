<template>
    <div class="tabs">
        <div class="tab_list">
            <div class="tab_item" @click="clickTabs(index)" :class="index == currentTab ? 'tab_item_active' : ''"
                v-for="(item, index) in tabList" :key="index">
                {{ item.title }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Tab',
    props: ['tabList', 'currentIndex'],
    data() {
        return {
            currentTab: 0
        }
    },
    watch: {
        currentIndex(val) {
          this.currentTab = val;
        }
    },
    methods: {
        clickTabs(index) {
            this.currentTab = index;
            this.$emit('tabChange', index);
        }
    }
}
</script>
<style lang="scss" scoped>
.tabs {
    .tab_list {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 2px #9faed01a solid;

        .tab_item {
            height: 3vmax;
            background: #20CED6;
            border-bottom: 1px solid #e5e6f2;
            font-size: 1.4vmax;
            line-height: 3vmax;
            text-align: center;
            color: #fff;
            border-radius: 0.4vmax;
            margin-left: 1vmax;
            transition: transform 0.3s;
            padding: 0 0.6vmax;
            /* 添加过渡效果 */
            // &:first-child {
            //     margin-left: 2vmax;
            // }
        }

        .tab_item_active {
            transform: scale(1.2)
        }
    }
}
</style>