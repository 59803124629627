<template>
    <div class="tabs">
        <div class="tab_list">
            <div class="tab_item" @click="clickTabs(item, index)" :class="index == currentTab ? 'tab_item_active' : ''"
                v-for="(item, index) in tabContentList" :key="index">
                {{ item.typeName }}
            </div>
        </div>
        <template v-if="tabContentList[currentTab].typeName == '解释说明'">
            <div class="senilityIntroduce">
                {{ tabContentList[currentTab].senilityIntroduce }}
            </div>
            <div class="senilityLiterature_title"> • 参考文献</div>
            <div class="senilityIntroduce_list" v-for="(item,index) in tabContentList[currentTab].senilityLiterature">
                {{ item }}
            </div>
        </template>
        <template v-else>
            <div class="disease" v-if="tabContentList[currentTab]">
                <div class="disease_list" @click="changeModele(item, index)"
                    v-for="(item, index) in tabContentList[currentTab].indicatorsSuggestions" :key="index">
                    <div class="disease_list_img">
                        <img class="wh100" :src="item.picture">
                    </div>
                    <div class="disease_list_text">
                        <div class="title">
                            {{ item.adviceTitle }}
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <div class="model">
            <Model :ModalBase="ModalBase" @handleClose="handleClose" @changeInside="handleClose"
                :animationState="animationState" v-if="isShowModel">
                <template v-slot:content>
                    <div class="model_content">
                        <div class="PersonHome_left_title">
                            <div class="round"></div>
                            <div class="info">改善指标</div>
                        </div>
                        <div class="improve">
                            <div class="improve_list" v-for="(item, index) in showContent.indicatorsList">
                                <div class="improve_list_left">
                                    <div class="title"> {{ item.indicatorsName }}</div>
                                    <div class="unit">{{ item.checkResult }}({{ item.indicatorsUnit }})</div>
                                </div>
                                <div class="improve_list_right">
                                    <div class="round" :style="{ background: item.colorCode }">
                                        {{ item.levelName }}
                                    </div>
                                    <div class="range">
                                        {{ item.refrence }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-for="(item, index) in showContent.suggestionList">
                            <div class="PersonHome_left_title">
                                <div class="round"></div>
                                <div class="info">{{ item.subtitle }}</div>
                            </div>
                            <div class="info_details" v-for="(item1, index1) in item.content">
                                {{ item1 }}
                            </div>
                        </div>
                    </div>
                </template>
            </Model>
        </div>
    </div>
</template>
<script>
import Model from '/src/views/userInformation/components/model.vue'
export default {
    name: 'TabS',
    props: ['tabContentList'],
    data() {
        return {
            currentTab: 0,
            isShowModel: false,
            animationState: true,
            showContent: '',
            ModalBase: {
                boxHight: '38vmax',
                boxWidth: '48vmax',
                boxBgc: '#2B2C3D'
            },
        }
    },
    components: {
        Model
    },
    methods: {
        clickTabs(item, index) {
            this.currentTab = index;
            this.tabSDetails = item
            console.log('item,', item);
        },
        changeModele(item, index) {
            this.isShowModel = true;
            this.showContent = item;
            console.log('item,', item);
        },
        handleClose() {
            // 关闭弹窗--动画重置
            this.animationState = false
            setTimeout(() => {
                this.isShowModel = false
                this.animationState = true
            }, 400)
        },
    }
}
</script>
<style lang="scss" scoped>
.model_content {
    height: 38vmax;

    .PersonHome_left_title {
        display: flex;
        align-items: center;
        padding-top: 1.8vmax;

        .round {
            width: 0.8vmax;
            height: 0.8vmax;
            background: #18BFC8;
            border-radius: 50%;
        }

        .info {
            // height: 3vmax;
            margin-left: 1vmax;
            background: #20D0D9;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vmax;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding: 0.2vmax 0.6vmax;
            border-radius: 0.5vmax;
        }
    }

    .info_details {
        font-size: 1.2vmax;
        padding-top: 1vmax
    }

    .improve {
        display: flex;
        overflow-y: scroll;

        .improve_list {
            display: flex;
            align-items: center;
            // justify-content: space-between;
            padding: 0 1vmax;
            margin-top: 1vmax;
            min-width: 18vmax;
            height: 4.8vmax;
            background: #38394D;
            border-radius: 10px 10px 10px 10px;
            color: #FFFFFF;
            margin-left: 0.6vmax;

            .improve_list_left {
                .title {
                    font-size: 1vmax;
                }

                .unit {
                    font-size: 0.8vmax;
                    padding-top: 0.6vmax;
                }
            }

            .improve_list_right {
                margin-left: 0.4vmax;

                .round {
                    width: 2vmax;
                    height: 1.4vmax;
                    line-height: 1.4vmax;
                    text-align: center;
                    background-color: red;
                    border-radius: 0.45vmax;
                    font-size: 0.7vmax;
                }

                .range {
                    padding-top: 0.6vmax;
                    font-size: 0.8vmax;
                }
            }
        }

        // .improve_list> :not(:first-child) {
        //     /* 样式规则 */
        //     margin-left: 2vmax;
        // }
    }
}

.tabs {
    .tab_list {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-bottom: 2px #9faed01a solid;

        .tab_item {
            height: 3vmax;
            background: #20CED6;
            border-bottom: 1px solid #e5e6f2;
            font-size: 1.4vmax;
            line-height: 3vmax;
            text-align: center;
            color: #fff;
            border-radius: 0.4vmax;
            margin-left: 1vmax;
            transition: transform 0.3s;
            padding: 0 0.6vmax;
            /* 添加过渡效果 */
            // &:first-child {
            //     margin-left: 2vmax;
            // }
        }

        .tab_item_active {
            transform: scale(1.2)
        }
    }
}

.senilityIntroduce {
    font-size: 1.2vmax;
    padding: 0 1vmax;
    padding-top: 1vmax;
}
.senilityLiterature_title{
    font-size: 1.4vmax;
    padding: 1vmax 0;
    font-weight: 600;
}
.senilityIntroduce_list{
    padding: 0.4vmax 0.2vmax;
    padding-right: 1vmax;
    font-size: 1.2vmax;
}
.disease {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;

    .disease_list {
        display: flex;
        overflow: hidden;
        width: 21vmax;
        height: 9vmax;
        box-shadow: 0px 10 10px 0px rgba(0, 0, 0, 0.25);
        border-radius: 10px 10px 10px 10px;
        box-sizing: content-box;
        display: flex;
        padding: 0 1.2vmax;
        margin-right: 1vmax;
        margin-top: 1vmax;
        flex-shrink: 0;
        align-items: center;
        background: #38394D;
        font-size: 1.2vmax;
        color: #FFFFFF;

        .disease_list_img {
            width: 7.4vmax;
            height: 7.4vmax;
            overflow: hidden;
            border-radius: 10px;
        }

        .disease_list_text {
            padding-left: 1.2vmax;
            width: 15vmax;

            .title {
                font-size: 1.4vmax;
            }

            .cycle {
                font-size: 1.2vmax;
                line-height: 2.5vmax;
            }

            .effect {
                font-size: 1vmax;
            }
        }
    }
}
</style>