<template>
  <div class="heart">
    <div class="heart_title">
      <div class="round"></div>
      <div class="info">生理年龄</div>
    </div>
    <div class="risk">
      <div class="risk_left">
        <template v-if="ageValue">
          <div class="age">
            <div class="age_number">{{ ageValue.userAge }}</div>
            <div class="age_text">当前年龄</div>
          </div>
          <div class="age">
            <div class="age_number">{{ ageValue.theAge }}</div>
            <div class="age_text">生理年龄</div>
          </div>
        </template>
        <div class="age">
          <div class="age_number">
            <template v-if="ageValue && ageValue.userAge && ageValue.theAge">
              {{ (ageValue.theAge * 10 - ageValue.userAge * 10)/10 }}
            </template>
          </div>
          <div class="age_text">年龄差</div>
        </div>
      </div>
      <div class="risk_right">
        <div ref="riskRithtChat" style="  width: 36vmax; height: 18vmax;"></div>
      </div>
    </div>
    <!-- <div class="heart_title" style="padding-top: 2vmax;">
      <div class="round"></div>
      <div class="info">改善建议</div>
    </div> -->
  </div>
</template>
<script>
import * as echarts from 'echarts'
export default {
  components: {

  },
  props: ['selectionParames'],
  data() {
    return {
      myChart: null,
      ageValue: null
    }
  },
  mounted() {
    // this.drawChart()
  },
  watch: {
    selectionParames: {
      immediate: true,
      deep: true, // 开启深度监听 专门对付复杂数据类型
      handler: function (newValueA, oldValue) {
        if (newValueA) {
          this.drawChart(newValueA)
        }
      }
    }
  },
  methods: {
    async drawChart(newValueA) {
      console.log(newValueA, '绘制图表2222')
      this.$nextTick(async () => {
        this.myChart = echarts.init(this.$refs.riskRithtChat)
        if (this.myChart) {
          const xAxisData = []
          const seriesData = []
          if (newValueA && newValueA.length > 0) {
            newValueA.map(v => {
              xAxisData.push(v.updateTime)
              seriesData.push(v.theAge)
            })
            this.ageValue = newValueA[newValueA.length - 1]
          }
          // 3. 指定图表的配置项和数据
          // 该处就是图表内容，在官网的示例里面，要复制过来到项目里面的也是这一块内容
          var option = {
            title: {
              text: ''
            },
            grid: {
              left: '6%',
              right: '0%',
              bottom: '10%',
              top: '3%',
              containLabel: true
            },
            tooltip: {},
            legend: { // 图例组件
              show: false // 设置不显示图例
            },
            xAxis: {
              data: xAxisData,
              axisTick: {
                show: false // 是否显示刻度线
              }

            },
            yAxis: {
              max: 120,
              axisLabel: {
                show: false
              },
              splitLine: {
                lineStyle: {
                  color: 'rgba(159,174,208,0.1)'
                }
              }

            },
            series: [{
              type: 'line',
              symbol: 'circle', // 设置类型为 circle
              symbolSize: 8, // 圆点大小
              data: seriesData,
              // silent: true, // 全局禁用点击事件
              // 设置线的颜色
              lineStyle: {
                color: '#BFBFC4',
                width: 1,
                type: 'solid'
              },
              // 设圆圈颜色
              itemStyle: {
                color: (params) => {
                  // const { color, data } = params
                  // if (data == 1) {
                  //   return '#10854A'
                  // }
                  // if (data == 2) {
                  //   return '#5EC692'
                  // }
                  // if (data == 3) {
                  //   return '#F9C716'
                  // }
                  // if (data == 4) {
                  //   return '#fe7283'
                  // }
                  // if (data == 5) {
                  //   return '#D51931'
                  // }
                  return '#5EC692'
                }
              }

              // itemStyle: {
              //     borderColor:'red'
              // }
              //   itemStyle: {
              //     color: '#262738',
              //     borderColor: 'red'
              //   }
            }]
          }
          // 4.使用刚指定的配置项和数据显示图表。
          this.myChart.setOption(option)
          // console.log('option', option)
        }
      }, 2)
    }
  }
}
</script>
<style lang='scss' scoped>
.heart {
  .heart_title {
    display: flex;
    align-items: center;

    .round {
      width: 0.8vmax;
      height: 0.8vmax;
      background: #18BFC8;
      border-radius: 50%;
    }
    .info1 {
      margin-left: 1vmax;
      padding: 0.2vmax 0.6vmax;
      background: #20D0D9;
      border-radius: 0.5vmax;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.6vmax;
      color: #FFFFFF;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-left: 0.6vmax;
    }
    .info {
      margin-left: 1vmax;
      padding: 0.2vmax 0.6vmax;
      background: #20D0D9;
      border-radius: 0.5vmax;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.6vmax;
      color: #FFFFFF;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-left: 0.6vmax;
    }
  }

  .risk {
    display: flex;
    padding-top: 1vmax;

    .risk_left {
      width: 7vmax;
      color: #FFFFFF;
      font-size: 1.4vmax;
      text-align: center;

      .age {
        padding-top: 2vmax;

        .age_number {}
      }
    }

    .risk_right {
      overflow-y: scroll;
      width: 100%;
      // background-color: #212230;
      margin-right: 2vmax;
    }
  }
}
</style>
