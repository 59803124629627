<template>
    <div class="PersonHome" v-if="info">
        <div class="PersonHome_left">
            <div class="PersonHome_left_title">
                <div class="round"></div>
                <div class="info">个人信息</div>
            </div>
            <div class="PersonHome_left_content">
                <span class="text">姓名：{{ info.userInfo.userName }}</span>
                <span class="text">性别：{{ info.userInfo.sex }}</span>
                <span class="text">出生年月：{{ info.userInfo.birthday }}</span>
                <span class="text">身高：{{ info.userInfo.height }}</span>
                <span class="text">体重：{{ info.userInfo.weight }} </span>
            </div>
            <div class="PersonHome_left_title">
                <div class="round"></div>
                <div class="info">健康风险</div>
            </div>
            <div class="disease">
                <div class="disease_list" :class="item.sickName == analysisName ? 'acitive' : ''"
                    @click="handleaAnalysis(1, item, index)"
                    :style="`background:${hexToRgb(item.colorCode, 0.1)}; borderColor:${item.colorCode}`"
                    v-for="(item, index) in info.sickList">
                    <div class="disease_list_round" :style="`background:${item.colorCode}`"></div>
                    <div class="disease_list_text">{{ item.sickName }}</div>
                </div>
            </div>
            <div class="PersonHome_left_title">
                <div class="round"></div>
                <div class="info">功能失衡</div>
            </div>
            <div class="unbalance">
                <div class="unbalance_item"
                 :class="item.unBalanceTitle == analysisName ? 'acitive' : ''"
                @click="handleaAnalysis(2, item, index)"
                    v-for="(item, index) in info.unBalanceList">
                    <div class="unbalance_item_title">
                        <div class="guide_box_tilte_text"> {{ item.unBalanceTitle }}</div>
                        <div class="guide_box_tilte_icon">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240822/388FA10D9AAC44708DC92029D9745D09.png"
                                alt="">
                        </div>
                    </div>
                    <div class="unbalance_box_line"></div>
                    <div class="guide_box_list" v-for="(item1, inde1) in item.balanceDetail">
                        <div class="round"></div>
                        <div class="text">{{ item1.showName }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="PersonHome_right">
            <div class="tabs">
                <Tab :tabList="tabList" @tabChange="tabChange" :currentIndex="currentIndex" />
            </div>
            <template v-if="currentIndex == 0">
                <div class="risk_analysis " v-if="analysisData">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">{{ analysisName }}概述</div>
                    </div>
                    <div class="risk_analysis_text" v-for="item in analysisData.sickIndroduce">
                        {{ item }}
                    </div>
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">{{ analysisName }}病因</div>
                    </div>
                    <div class="risk_analysis_text" v-for="item in analysisData.sickReason">
                        {{ item }}
                    </div>
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">{{ analysisName }}风险因素</div>
                    </div>
                    <div class="risk_analysis_text" v-for="item in analysisData.riskFactor">
                        {{ item }}
                    </div>
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">{{ analysisName }}症状</div>
                    </div>
                    <div class="risk_analysis_text" v-for="item in analysisData.symptom">
                        {{ item }}
                    </div>
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">{{ analysisName }}并发症</div>
                    </div>
                    <div class="risk_analysis_text" v-for="item in analysisData.complication">
                        {{ item }}
                    </div>
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">{{ analysisName }}调整饮食与生活方式</div>
                    </div>
                    <div class="risk_analysis_text" v-for="item in analysisData.foodAndLifeAdvice">
                        {{ item }}
                    </div>
                </div>
            </template>
            <template v-else-if="currentIndex == 1">
                <div class="nutritional" v-if="productInfo">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">建议产品</div>
                    </div>
                    <div class="product">
                        <div class="product_pic">
                            <img :src="productInfo.productPic" class="wh100">
                        </div>
                        <div class="product_text">
                            {{ productInfo.productName }}
                        </div>
                    </div>
                    <div class="PersonHome_left_title" style="padding-bottom: 1.4vmax;">
                        <div class="round"></div>
                        <div class="info1">成份说明</div>
                    </div>
                    <div class="Ingredients" v-for="(item, index) in productInfo.effectList" :key="index">
                        <div class="Ingredients_title">
                            <div class="Ingredients_round">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240905/99B9AFB4F1F8410E98CEC5C5C1879EB1.png"
                                    alt="">
                            </div>
                            <div class="Ingredients_text">
                                {{ item.effect }}：{{ item.ingredient }}
                            </div>
                        </div>
                        <div class="mechanism">
                            {{ item.effectMechinasm }}
                        </div>
                        <div class="literature">
                            {{ item.literature }}
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="currentIndex == 2">
                <div class="nutritional" v-if="innovateProductInfo">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info1">建议产品</div>
                    </div>
                    <div class="product">
                        <div class="product_pic">
                            <img :src="innovateProductInfo.productPic" class="wh100">
                        </div>
                        <div class="product_text">
                            {{ innovateProductInfo.productName }}
                        </div>
                    </div>
                    <div class="PersonHome_left_title" style="padding-bottom: 1.4vmax;">
                        <div class="round"></div>
                        <div class="info1">成份说明</div>
                    </div>
                    <div class="Ingredients" v-for="(item, index) in innovateProductInfo.effectList" :key="index">
                        <div class="Ingredients_title">
                            <div class="Ingredients_round">
                                <img class="wh100"
                                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240905/99B9AFB4F1F8410E98CEC5C5C1879EB1.png"
                                    alt="">
                            </div>
                            <div class="Ingredients_text">
                                {{ item.effect }}：{{ item.ingredient }}
                            </div>
                        </div>
                        <div class="mechanism">
                            {{ item.effectMechinasm }}
                        </div>
                        <div class="literature">
                            {{ item.literature }}
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import {
    GetHealthManagementPlan
} from '@/api/index'
import Tab from '/src/views/userInformation/components/Tab.vue'
export default {
    components: {
        Tab
    },
    data() {
        return {
            tabList: [
                {
                    id: 0,
                    title: '风险分析'
                },
                {
                    id: 1,
                    title: '营养干预'
                },
                {
                    id: 1,
                    title: '医疗干预'
                }
            ],
            info: null,
            currentIndex: 0,
            analysisData: null,
            analysisName: null,
            productInfo: null,
            innovateProductInfo: null
        }
    },
    created() {
        this.getInfo()
    },
    mounted() {

    },
    methods: {
        hexToRgb(hex, opt) {
            hex = hex.replace('#', '')
            var red = parseInt(hex[0] + hex[1], 16)
            var green = parseInt(hex[2] + hex[3], 16)
            var blue = parseInt(hex[4] + hex[5], 16)
            return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
        },
        tabChange(index) {
            this.currentIndex = index
            console.log('currentIndex', index);
        },
        handleaAnalysis(type, item, index) {
            this.currentIndex = 0
            if (type == 1) {
                this.analysisName = item.sickName
            }
            if (type == 2) {
                this.analysisName = item.unBalanceTitle
            }
            this.analysisData = item.sickIntroduce
            this.productInfo = item.productInfo
            this.innovateProductInfo = item.innovateProductInfo
            console.log('dataaaa', type, index, item);
        },
        getInfo() {
            const customerCode = this.$query('params')
            let parames = {
                customerCode
            }
            GetHealthManagementPlan(parames).then(res => {
                this.info = res.data
                if (res.data.sickList && res.data.sickList.length > 0) {
                    this.analysisData = res.data.sickList[0].sickIntroduce
                    this.analysisName = res.data.sickList[0].showSickName
                } else {
                    this.analysisData = res.data.unBalanceList[0].sickIntroduce
                    this.analysisName = res.data.unBalanceList[0].unBalanceTitle
                }
            })
        }
    }
}
</script>
<style lang='scss' scoped>
.PersonHome {
    display: flex;

    .acitive {
        border: 4px #20D0D9 solid !important;
    }

    .PersonHome_left_title {
        display: flex;
        align-items: center;
        padding-top: 1.8vmax;

        .round {
            width: 0.8vmax;
            height: 0.8vmax;
            background: #18BFC8;
            border-radius: 50%;
        }

        .info {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vmax;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding-left: 0.6vmax;
        }

        .info1 {
            margin-left: 1vmax;
            padding: 0.2vmax 0.6vmax;
            background: #20D0D9;
            border-radius: 0.5vmax;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vmax;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding-left: 0.6vmax;
        }
    }

    .PersonHome_left {
        overflow-y: auto;
        width: 48vmax;
        height: 59.4vmax;
        background: #2B2C3C;
        border-radius: 1vmax;
        margin-left: 1vmax;
        padding-left: 2.5vmax;



        .PersonHome_left_content {
            padding-top: 1vmax;

            .text {
                padding-left: 0.8vmax;
                width: 4.8vmax;
                font-size: 1.2vmax;
            }
        }

        .disease {
            display: flex;
            flex-wrap: nowrap;
            overflow-x: auto;

            .disease_list {
                box-sizing: content-box;
                display: flex;
                padding: 0 1.2vmax;
                margin-right: 1vmax;
                margin-top: 1vmax;
                flex-shrink: 0;
                align-items: center;
                height: 3vmax;
                background: rgba(253, 214, 76, 0.1);
                border-radius: 0.8vmax;
                border: 1px solid rgba(253, 214, 76, 0.5);
                line-height: 3vmax;
                text-align: center;
                font-size: 1.2vmax;
                color: #FFFFFF;
                font-style: normal;
                text-transform: none;

                .disease_list_round {
                    flex-shrink: 0;
                    width: 0.4vmax;
                    height: 0.4vmax;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                }

                .disease_list_text {
                    flex-shrink: 0;
                    padding-left: 0.3vmax;
                }
            }
        }

        .unbalance {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-right: 2.5vmax;
            color: #FFFFFF;

            .unbalance_item {
                overflow-y: auto;
                width: 20vmax;
                height: 19vmax;
                margin-top: 1vmax;
                background: #38394D;
                border-radius: 2vmax;
                padding-top: 1vmax;

                .unbalance_item_title {
                    font-size: 1.4vmax;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .guide_box_tilte_text {
                        font-size: 1.4vmax;
                    }

                    .guide_box_tilte_icon {
                        width: 0.8vmax;
                        height: 1.3vmax;
                        margin-top: 0.5vmax;
                        margin-left: 0.2vmax;
                    }
                }

                .unbalance_box_line {
                    width: 100%;
                    height: 1px;
                    margin-top: 0.2vmax;
                    border: 1px solid;
                    border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
                }

                .guide_box_list {
                    display: flex;
                    align-items: center;
                    font-size: 1.2vmax;
                    padding-left: 1vmax;
                    padding-top: 0.8vmax;

                    .round {
                        width: 0.4vmax;
                        height: 0.4vmax;
                        background-color: #FFFFFF;
                        border-radius: 50%;
                    }

                    .text {
                        padding-left: 0.4vmax;
                    }
                }
            }
        }
    }

    .PersonHome_right {
        // overflow-y: auto;
        width: 41vmax;
        height: 59.4vmax;
        background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
        border-radius: 1vmax;
        margin-left: 1vmax;
        padding-left: 2.5vmax;

        .tabs {
            padding-top: 1vmax;
        }

        .risk_analysis {
            height: 52vmax;
            padding-top: 1vmax;
            font-size: 1.4vmax;
            padding-right: 1vmax;
            overflow-y: scroll;

            .risk_analysis_title {
                line-height: 4vmax;
            }

            .risk_analysis_text {
                font-size: 1.2vmax;
                padding-left: 1vmax;
                padding-top: 1vmax;
            }
        }

        .nutritional {
            height: 52vmax;
            overflow-y: scroll;
            padding-left: 2vmax;

            .product {
                padding-left: 1vmax;

                .product_pic {
                    width: 7vmax;
                    height: 7vmax;
                    margin-top: 2vmax;

                    border-radius: 0.6vmax;
                    overflow: hidden;
                }

                .product_text {
                    // width: 8vmax;
                    font-size: 1.2vmax;
                    padding-top: 0.8vmax;
                    // text-align: center;
                }
            }

            .Ingredients {
                padding: 0.6vmax 0;
                padding-right: 2vmax;

                .Ingredients_title {
                    font-size: 1.4vmax;
                    display: flex;
                    align-items: center;

                    .Ingredients_round {
                        width: 1vmax;
                        height: 1vmax;
                        // border-radius: 50%;
                        // background-color: #FFFFFF;
                    }

                    .Ingredients_text {
                        padding-left: 0.6vmax;
                    }
                }

                .mechanism {
                    padding: 0.6vmax 0;
                    margin-left: 1vmax;
                    font-size: 1.2vmax;
                }

                .literature {
                    padding-left: 1vmax;
                    font-size: 1.2vmax;
                }

                // .mechanism{
                //     max-width: 0.6vmax 0;
                // }
                // .mechanism {
                //     padding-left: 1vmax;
                //    padding: 0.6vmax 0;
                // }
            }
        }

        .medical {}
    }
}
</style>