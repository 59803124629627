<template>
    <div class="PersonHome" v-if="info">
        <div class="PersonHome_left">
            <div v-for="(item, index) in info" :key="index">
                <div class="PersonHome_left_title">
                    <div class="round"></div>
                    <div class="info1">{{ item.typeName }}</div>
                </div>
                <div class="disease">
                    <div class="disease_list" @click="changeRigthDetails(item1, index1)"
                        v-for="(item1, index1) in item.indicatorsSuggestions">
                        <div class="disease_list_img">
                            <img class="wh100" :src="item1.picture">
                        </div>
                        <div class="disease_list_text">
                            <div class="title">
                                {{ item1.adviceTitle }}
                            </div>
                            <!-- <div class="cycle">
                                {{ item1.frequency }}
                            </div>
                            <div class="effect">
                                <span style="color:#5EC692"> {{ item1.impactStandards }}</span>
                                <span style="padding-left: 0.6vmax;"> {{ item1.impactRemark }}</span>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="PersonHome_right">
            <div v-if="rightDetails">
                <div class="PersonHome_left_title">
                    <div class="round"></div>
                    <div class="info">改善指标</div>
                </div>
                <div class="improve">
                    <div class="improve_list" v-for="(item, index) in rightDetails.indicatorsList">
                        <div class="improve_list_right"> 
                            <div class="range">
                                {{ item.indicatorsName }}
                            </div>
                            <div class="round" :style="{ background: item.colorCode }">
                                {{ item.levelName }}
                            </div>
                        </div>
                        <div class="improve_list_left" style="display: flex;">
                            <div class="unit">{{ item.checkResult }}({{ item.indicatorsUnit }})</div>
                            <div class="unit" style="padding-left: 2vmax;"> {{ item.refrence }}</div>
                        </div>
                        <!-- <div class="improve_list_left">
                            <div class="unit"> {{ item.refrence }}</div>
                        </div> -->
                    </div>
                </div>
                <div v-for="(item, index) in rightDetails.suggestionList">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info">{{ item.subtitle }}</div>
                    </div>
                    <div class="suggestion" v-for="(item1, index1) in item.content">
                        {{ item1 }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {
    GetUserIndicatorsSuggestions
} from '@/api/index'
import Tab from '/src/views/userInformation/components/Tab.vue'
export default {
    components: {
        Tab
    },
    data() {
        return {
            tabList: [
                {
                    id: 0,
                    title: '风险分析'
                },
                {
                    id: 1,
                    title: '营养干预'
                },
                {
                    id: 1,
                    title: '医疗干预'
                }
            ],
            info: null,
            currentIndex: 0,
            rightDetails: null
        }
    },
    created() {
        this.getInfo()
    },
    mounted() {

    },
    methods: {
        changeRigthDetails(item, index) {
            this.rightDetails = item
            console.log('item', item, 'index', index);

        },
        getInfo() {
            const customerCode = this.$query('params')
            let parames = {
                customerCode
            }
            GetUserIndicatorsSuggestions(parames).then(res => {
                console.log('res', res);
                this.info = res.data
            })
        }
    }
}
</script>
<style lang='scss' scoped>
.PersonHome {
    display: flex;

    .PersonHome_left_title {
        display: flex;
        align-items: center;
        padding-top: 1.8vmax;

        .round {
            width: 0.8vmax;
            height: 0.8vmax;
            background: #18BFC8;
            border-radius: 50%;
        }

        .info1 {
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vmax;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding-left: 0.6vmax;
        }

        .info {
            margin-left: 1vmax;
            padding: 0.2vmax 0.6vmax;
            background: #20D0D9;
            border-radius: 0.5vmax;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vmax;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding-left: 0.6vmax;
        }
    }

    .PersonHome_left {
        overflow-y: auto;
        width: 48vmax;
        height: 59.4vmax;
        background: #2B2C3C;
        border-radius: 1vmax;
        margin-left: 1vmax;
        padding-left: 2.5vmax;
        padding-bottom: 1.5vmax;

        .PersonHome_left_content {
            padding-top: 1vmax;

            .text {
                padding-left: 0.8vmax;
                width: 4.8vmax;
                font-size: 1.2vmax;
            }
        }

        .disease {
            display: flex;
            flex-wrap: wrap;
            overflow-x: auto;

            .disease_list {
                display: flex;
                min-width: 18.8vmax;
                height: 9vmax;
                box-shadow: 0px 10 10px 0px rgba(0, 0, 0, 0.25);
                border-radius: 10px 10px 10px 10px;
                box-sizing: content-box;
                display: flex;
                padding: 0 1.2vmax;
                margin-right: 1vmax;
                margin-top: 1vmax;
                flex-shrink: 0;
                align-items: center;
                background: #38394D;
                font-size: 1.2vmax;
                color: #FFFFFF;

                .disease_list_img {
                    width: 7.4vmax;
                    height: 7.4vmax;
                    height: 7.4vmax;
                    overflow: hidden;
                    border-radius: 10px;
                }

                .disease_list_text {
                    padding-left: 1.2vmax;
                    width: 11vmax;

                    .title {
                        font-size: 1.4vmax;
                    }

                    .cycle {
                        font-size: 1.2vmax;
                        line-height: 2.5vmax;
                    }

                    .effect {
                        font-size: 1vmax;
                    }
                }
            }
        }

        .unbalance {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            padding-right: 2.5vmax;
            color: #FFFFFF;

            .unbalance_item {
                overflow-y: auto;
                width: 20vmax;
                height: 19vmax;
                margin-top: 1vmax;
                background: #38394D;
                border-radius: 2vmax;
                padding-top: 1vmax;

                .unbalance_item_title {
                    text-align: center;
                    font-size: 1.4vmax;
                }

                .unbalance_box_line {
                    width: 100%;
                    height: 1px;
                    margin-top: 0.2vmax;
                    border: 1px solid;
                    border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
                }

                .guide_box_list {
                    display: flex;
                    align-items: center;
                    font-size: 1.2vmax;
                    padding-left: 1vmax;
                    padding-top: 0.8vmax;

                    .round {
                        width: 0.4vmax;
                        height: 0.4vmax;
                        background-color: #FFFFFF;
                        border-radius: 50%;
                    }

                    .text {
                        padding-left: 0.4vmax;
                    }
                }
            }
        }
    }

    .PersonHome_right {
        width: 41vmax;
        height: 59.4vmax;
        background: #2B2C3C;
        border-radius: 1vmax;
        margin-left: 1vmax;
        padding-left: 2.5vmax;
        padding-bottom: 1.5vmax;
        overflow-y: scroll;

        .improve {
            display: flex;
            overflow-y: scroll;

            .improve_list {
                padding: 0 1vmax;
                margin-top: 1vmax;
                min-width: 17vmax;
                height: 4.8vmax;
                background: #38394D;
                border-radius: 10px 10px 10px 10px;
                color: #FFFFFF;
                margin-left: 0.6vmax;

                .improve_list_left {
                    padding-left: 0.4vmax;
                    .title {
                        font-size: 1vmax;
                    }

                    .unit {
                        font-size: 0.8vmax;
                        padding-top: 0.6vmax;
                    }
                }

                .improve_list_right {
                    display: flex;
                    align-items: center;
                    margin-left: 0.4vmax;

                    .round {
                        margin-top: 0.6vmax;
                        margin-left: 0.2vmax;
                        width: 2vmax;
                        height: 1.4vmax;
                        line-height: 1.4vmax;
                        text-align: center;
                        background-color: red;
                        border-radius: 0.45vmax;
                        font-size: 0.7vmax;
                    }

                    .range {
                        padding-top: 0.6vmax;
                        font-size: 0.8vmax;
                    }
                }
            }
        }

        .suggestion {
            padding-top: 1vmax;
            font-size: 1.2vmax;
            padding-right: 2vmax
        }
    }
}
</style>