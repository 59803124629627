<template>
    <div class="IndicatorsBox_li_secondary_li">
        <div class="IndicatorsBox_li_secondary_title" v-if="state.showContent.relatedHealth">
            此指标与哪些健康相关: {{ state.showContent.relatedHealth }}
        </div>
        <div class="IndicatorsBox_li_secondary_li_title a_flex" style="display: inline-flex;">
            {{ state.showContent.indicatorsName }}
        </div>
        <div class="IndicatorsBox_li_secondary_li_info">
            <div class="IndicatorsBox_li_secondary_li_info_img a_flex">
                <img class="wh100"
                    src="https://img.giantgocloud.com/www/ImageMapping/img/20240329/631B1D456F1B42E0B0F888B5E544E1CA.png"
                    mode="widthFix"></img>
            </div>
            <div class="IndicatorsBox_li_secondary_li_info_text">
                该指标等级为
            </div>
            <div class="IndicatorsBox_li_secondary_li_info_level" :style="'color:' + state.showContent.colorCode + ';'">
                {{ state.showContent.colorLevel }}
            </div>
        </div>
        <div class="IndicatorsBox_li_secondary_li_info2">
            <div class="IndicatorsBox_li_secondary_li_info2_ul">
                <span v-for="(item2, index2) in state.showContent.colorList" :key="index2">
                    <div v-if="index2 != 0" style="width: 0.5vmax;" class="">

                    </div>
                    <div :style="'background:' + item2.colorCode + ';'"
                        class="IndicatorsBox_li_secondary_li_info2_li a_flex">

                    </div>
                </span>
            </div>
            <div :style="'background:' + state.showContent.colorCode + ';left:' + state.showContent.calcResult + '%;'"
                class="IndicatorsBox_li_secondary_li_info2_d a_flex">
                <div v-if="state.showContent.checkResult && state.showContent.indicatorsUnit"
                    class="IndicatorsBox_li_secondary_li_info2_d_text">
                    {{ state.showContent.checkResult }}({{ state.showContent.indicatorsUnit }})
                </div>
            </div>
            <div class="IndicatorsBox_li_secondary_li_info2_ulA">
                <div v-for="(item2, index2) in state.showContent.colorList" :key="index2"
                    class="IndicatorsBox_li_secondary_li_info2_li a_flex">
                    <div class="IndicatorsBox_li_secondary_li_info2_li_colorName">
                        {{ item2.colorName }}
                    </div>
                </div>
            </div>
            <div class="IndicatorsBox_li_secondary_li_info1_title">
                指标解释:
            </div>
            <div class="IndicatorsBox_li_secondary_li_info1">
                <div class="IndicatorsBox_li_secondary_li_info1_left">
                    <div class="IndicatorsBox_li_secondary_li_info1_text">
                        {{ state.showContent.indicatorsDescription }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Indicator',
    props: ['IndicatorData'],
    data() {
        return {
            aa: 1
        }
    }
}
</script>
<style lang="scss" scoped>
.IndicatorsBox_li_secondary_li {
    margin-top: 1.4vmax;
    margin-left: 1.2vmax;
    .IndicatorsBox_li_secondary_title {
        color: #fff;
        font-size: 1.8vmax;
        padding-bottom: 2vmax;
    }
    .IndicatorsBox_li_secondary_li_title {
        padding: 0.5vmax 1.4vmax;
        background: rgba(32, 206, 214, 0.27);
        border-radius: 0.6vmax;
        font-size: 1.8vmax;
        color: #FFFFFF;
    }

    .IndicatorsBox_li_secondary_li_info {
        display: flex;
        align-items: center;
        margin-top: 1.8vmax;

        .IndicatorsBox_li_secondary_li_info_img {
            width: 2.6vmax;
            height: 2.6vmax;
        }

        .IndicatorsBox_li_secondary_li_info_text {
            margin-left: 1vmax;
            font-size: 1.8vmax;
            color: #FFFFFF;
        }

        .IndicatorsBox_li_secondary_li_info_level {
            margin-left: 1vmax;
            font-size: 1.8vmax;
        }
    }

    .IndicatorsBox_li_secondary_li_info1_title {
        color: #fff;
        font-size: 1.8vmax;
        padding-top: 2vmax;
    }

    .IndicatorsBox_li_secondary_li_info1 {
        margin-top: 2vmax;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 1.6vmax;

        .IndicatorsBox_li_secondary_li_info1_left {
            display: flex;
            align-items: center;
            width: 63vmax;

            .IndicatorsBox_li_secondary_li_info1_left_d {
                flex-shrink: 0;
                position: relative;
                top: 0.2vmax;
                width: 1vmax;
                height: 1vmax;
                border-radius: 50%;
            }

            .IndicatorsBox_li_secondary_li_info1_text {
                margin-left: 1vmax;
                font-size: 1.6vmax;
                color: #FFFFFF;
                // text-overflow: ellipsis;
                // overflow: hidden;
                // white-space: nowrap;
            }
        }
    }

    .IndicatorsBox_li_secondary_li_info2 {
        position: relative;
        margin-top: 6vmax;
        width: 100%;

        .IndicatorsBox_li_secondary_li_info2_ul {
            position: relative;
            border-radius: 3vmax;
            height: 1.3vmax;
            overflow: hidden;
            display: flex;
            width: 100%;

            .IndicatorsBox_li_secondary_li_info2_li {
                height: 1.3vmax;
                flex: 1;

                .IndicatorsBox_li_secondary_li_info2_li_colorName {
                    font-weight: 500;
                    font-size: 1.8vmax;
                    color: #FFFFFF;
                }
            }
        }

        .IndicatorsBox_li_secondary_li_info2_ulA {
            width: 100%;
            position: relative;
            margin-top: 2vmax;
            border-radius: 3vmax;
            height: 1.3vmax;
            display: flex;
            width: 100%;

            .IndicatorsBox_li_secondary_li_info2_li {
                height: 1.3vmax;
                flex: 1;

                .IndicatorsBox_li_secondary_li_info2_li_colorName {
                    font-weight: 500;
                    font-size: 1.8vmax;
                    color: #FFFFFF;
                }
            }
        }

        .IndicatorsBox_li_secondary_li_info2_d {
            position: absolute;
            top: -1.2vmax;
            width: 3vmax;
            height: 3vmax;
            border-radius: 50%;
            border: 0.1vmax solid #FFFFFF;
            transform: translateX(-1.5vmax);

            .IndicatorsBox_li_secondary_li_info2_d_text {
                position: relative;
                top: -4vmax;
                font-size: 1.8vmax;
                color: #FFFFFF;
            }
        }
    }
}
</style>