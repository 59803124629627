<template>
  <div class="achievement_pages">
    <div class="achievement_pages_left">
      <div class="achievement_pages_left_top">
        <div class="achievement_pages_left_top_left">
          <div class="achievement_pages_left_top_left_title">
            成绩单
          </div>
          <div class="achievement_pages_left_top_left_time">
            截至 2023-03-22
          </div>
          <div class="transcript">
            <div class="result">
              <img
                src="https://image.giantgocloud.com/www/ImageMapping/image/20240819/899383F709A34B67A972DDA573BB7C51.png"
                class="wh100">
            </div>
            <div class="sleep">
              <div class="sleep_list">
                <div class="sleep_text">
                  <div class="sleep_text_left">
                    真实年龄
                  </div>
                  <div class="sleep_text_right" v-if="ageProgress && ageProgress.userAge">
                    {{ ageProgress.userAge }}岁
                  </div>
                </div>
                <div class="sleep_line">
                  <div class="sleep_line_item"
                    :style="{ width: `${ageProgress && ageProgress.userAge ? ageProgress.userAge : 0}%` }"></div>
                </div>
              </div>
              <div class="sleep_list1">
                <div class="sleep_text">
                  <div class="sleep_text_left">
                    生理年龄
                  </div>
                  <div class="sleep_text_right" v-if="ageProgress && ageProgress.theAge">
                    {{ ageProgress.theAge }}岁
                  </div>
                </div>
                <div class="sleep_line">
                  <div class="sleep_line_item"
                    :style="{ width: `${ageProgress && ageProgress.theAge ? ageProgress.theAge : 0}%` }">
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="achievement_pages_left_top_rigth_Proportion"
              style="padding-left: 3vmax;padding-top: 2.5vmax;font-size:1vmax">
              <div class="last_proportion" v-if="lastTime">
                <div style="display: flex;">
                  <span>{{ lastTime.theAge }}（{{ lastTime.physiologyRate ? lastTime.physiologyRate : 0 }}%）</span>
                  <template v-if="lastTime.type == 1">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                  </template>
                  <template v-if="lastTime.type == 2">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                  </template>
                </div>
                <div style="line-height: 2vmax;">与上次访问相比的变化</div>
                <div>截至 {{ lastTime.updateTime }}</div>
              </div>
              <div class="last_proportion" v-if="peerTime">
                <div style="display: flex;">
                  <span>{{ peerTime.theAge }}（{{ peerTime.physiologyRate ? peerTime.physiologyRate : 0 }}%）</span>
                  <template v-if="peerTime.type == 1">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                  </template>
                  <template v-if="peerTime.type == 2">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                  </template>
                </div>
                <div style="line-height: 2vmax;">
                  超过 {{ peerTime.physiologyRate }}%同龄人
                </div>
                <div>截至 {{ peerTime.updateTime }}</div>
              </div>
            </div> -->
          </div>
        </div>
        <!-- <div class="achievement_pages_left_top_rigth">
          <div class="achievement_pages_left_top_rigth_Proportion">
            <div class="last_proportion" v-if="lastTime">
              <div style="display: flex;">
                <span>{{ lastTime.theAge }}（{{ lastTime.physiologyRate ? lastTime.physiologyRate : 0 }}%）</span>
                <template v-if="lastTime.type == 1">
                  <img style="width: 1vmax;height: 1vmax;"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                </template>
                <template v-if="lastTime.type == 2">
                  <img style="width: 1vmax;height: 1vmax;"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                </template>
              </div>
              <div style="line-height: 2vmax;">与上次访问相比的变化</div>
              <div>截至 {{ lastTime.updateTime }}</div>
            </div>
            <div class="last_proportion" v-if="peerTime">
              <div style="display: flex;">
                <span>{{ peerTime.theAge }}（{{ peerTime.physiologyRate ? peerTime.physiologyRate : 0 }}%）</span>
                <template v-if="peerTime.type == 1">
                  <img style="width: 1vmax;height: 1vmax;"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                </template>
                <template v-if="peerTime.type == 2">
                  <img style="width: 1vmax;height: 1vmax;"
                    src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                </template>
              </div>
              <div style="line-height: 2vmax;">
                超过 {{ peerTime.physiologyRate }}%同龄人
              </div>
              <div>截至 {{ peerTime.updateTime }}</div>
            </div>
          </div>
        </div> -->
      </div>
      <div class="achievement_pages_left_bottom">
        <div class="achievement_pages_left_bottom_table">
          <div class="achievement_pages_left_bottom_table_tab">
            <div class="achievement_pages_left_bottom_table_title" style="width: 3.5vmax;flex: none;">等级</div>
            <div class="achievement_pages_left_bottom_table_title" style="width: 11vmax;flex: none;">指标名称</div>
            <div class="achievement_pages_left_bottom_table_title"
              style="width: 11vmax;flex: none;padding-left: 3vmax;">结果
            </div>
            <div class="achievement_pages_left_bottom_table_title" style="width: 11vmax;flex: none;">参考范围</div>
          </div>
          <div class="achievement_pages_left_bottom_table_content">
            <div v-for="(item, index) in IndicatorData" :key="index" @click="clickListData(item)"
              class="achievement_pages_left_bottom_table_content_li">
              <div class="achievement_pages_left_bottom_table_content_li_h"></div>
              <div class="achievement_pages_left_bottom_table_content_li_ul">
                <div class="achievement_pages_left_bottom_table_content_li_ul_li a_flex"
                  style="width: 2.5vmax;flex: none;">
                  <div :style="'background:' + item.colorCode"
                    class="achievement_pages_left_bottom_table_content_li_ul_li_level a_flex">
                    {{ item.colorLevel }}
                  </div>
                </div>
                <div class="achievement_pages_left_bottom_table_content_li_ul_li"
                  style="width: 11vmax;text-align: left;">
                  {{ item.indicatorsName }}
                </div>
                <div :style="'color:' + item.lastLevelCode"
                  class="achievement_pages_left_bottom_table_content_li_ul_li a_flex" style="width: 4vmax;flex: none;">
                  {{ item.checkResult }}({{ item.indicatorsUnit }})
                </div>
                <div class="achievement_pages_left_bottom_table_content_li_ul_li a_flex"
                  style="width: 9vmax;flex: none;">
                  {{ item.refrence }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="achievement_pages_right">
      <div>
        <biologicalAgeHeart :selectionParames="currentOrgin" />
      </div>
      <div class="tab" v-if="tabContentList">
        <TabS :tabContentList="tabContentList" />
      </div>
      <div v-else>
        <div class="PersonHome_left_title">
          <div class="round"></div>
          <div class="info">什么是生理年龄？</div>
        </div>
        <div class="bio_title">
          优化健康寿命的革新性生物年龄模型
        </div>
        <div class="bio_content">
          你熟悉自己的“实际年龄”，这是每当别人问你几岁时所引用的数字。然而，实际年龄无法反映一个人的真实健康状态，因为每个人的衰老速度都不同——有些人衰老得更快，有些人则更慢。因此，生日的次数并不能完全说明你身体内部的生物年龄。比如，两个40岁的人在外观和健康状况上可能差异巨大，这取决于他们的生活方式、饮食、运动习惯、压力管理和睡眠质量等因素。
        </div>
        <div class="bio_content" style="padding-top: 1vmax;">
          生理年龄，也称为“InnerAge”，是一种衡量你身体内部衰老速度的指标。它基于一系列生物标志物的分析，揭示出让你衰老或保持年轻的关键因素。如果你的实际年龄是50岁，你的生理年龄可能是45岁，也可能是55岁，具体取决于你的健康状况。了解这一点，能够帮助你采取针对性措施，延缓衰老过程，提升健康寿命。
        </div>
        <div class="bio_title">
          为什么了解生理年龄很重要？
        </div>
        <div class="bio_content">
          了解你的生理年龄可能让人感到些许压力，但实际上，它是你掌控健康的重要起点。生理年龄是帮助人们显著改善健康寿命的革命性工具，它为你提供了关于衰老过程的全面理解。通过分析与健康寿命密切相关的生物标志物，例如血糖、炎症指标和甘油三酯等，生理年龄可以揭示出你的身体表现如何，以及它相较于实际年龄应当处于何种状态。
        </div>
        <div class="bio_content" style="padding-top: 1vmax;">
          因为我们日常的行为和生活方式可以显著影响衰老进程，基于生理年龄的测算，我们可以为每个用户提供超个性化的营养和生活方式建议，帮助你优化健康寿命，提升未来生活质量。
        </div>
        <div class="bio_title">
          我们生理年龄测算的三大优势
        </div>
        <div class="bio_content">
          更多的生物标志物 = 更高的精度和准确度
          我们的生理年龄测算模型覆盖了女性14种和男性18种关键生物标志物，远超传统的简单测算方式。这些标志物涵盖了从心血管健康（如LDL“坏”胆固醇）到肝功能（如GGT）的广泛范围。此外，它们还涉及炎症、免疫功能、血氧饱和度以及BMI等指标。因此，通过提升你的InnerAge，不仅可以有效降低生理年龄，还可以增强你身体抵御疾病和感染的能力，全面优化健康状态。
        </div>
        <div class="bio_content" style="padding-top: 1vmax;">
          全球领先的数据驱动模型
          我们的生理年龄模型不仅参考了传统的国际研究和文献数据，还基于我们庞大的用户数据集进行了独特的优化。与国际同行合作的过程中，我们使用的数据不仅限于病人，而是来自我们更健康、更活跃的用户群体。这是一个关键区别，因为健康的个体通常在同年龄段的生物标志物水平上表现得更好。因此，我们的生理年龄模型可以为你提供基于更高健康标准的精准评估，让你更清楚自己相对于更健康群体的表现。
        </div>
        <div class="bio_content" style="padding-top: 1vmax;">
          更稳健、更精准的计算模型
          在确定生理年龄时，我们为每个生物标志物赋予了不同的权重，这取决于其与年龄变化的相关性和其对整体健康的贡献。例如，葡萄糖与衰老的相关性比肝酶GGT更强，因此在生理年龄的计算中，葡萄糖占据了更高的权重。通过这种权重计算，我们可以更准确地评估每个标志物对你生理年龄的影响，并为你提供一个全面、客观的生理年龄结果。这种精细化的计算让我们的生理年龄测算更加稳健和可靠。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import biologicalAgeHeart from '/src/views/userInformation/components/biologicalAgeHeart.vue'
import TabS from '/src/views/userInformation/components/TabS.vue'
import Indicator from '/src/views/userInformation/components/Indicator.vue'

import {
  GetPhysiologyInfo,
  GetSuggestionsByIndicators
} from '@/api/index'
export default {
  components: {
    biologicalAgeHeart,
    TabS,
    Indicator
  },
  data() {
    return {
      ifShowR: false,
      showCurentIndex: 0,
      listData: [],
      IndicatorData: [],
      ageProgress: null,
      lastTime: null,
      peerTime: null,
      currentOrgin: null,
      tabContentList: null
    }
  },
  props: {

  },
  created() {

  },
  mounted() {
    this.GetUserOrganHealingInfoF()
  },
  methods: {
    async clickListData(item) {
      console.log('item', item);
      const customerCode = this.$query('params')
      let { data } = await GetSuggestionsByIndicators({ indicatorId: item.indicatorId, customerCode })
      if (data && data.length > 0) {
        this.tabContentList = data
      }else{
        this.tabContentList = []
      }
      console.log('data', data);

    },
    GetUserOrganHealingInfoF() {
      const customerCode = this.$query('params')
      const parames = {
        customerCode
      }
      GetPhysiologyInfo(parames).then(res => {
        this.currentOrgin = res.data.physiologyList
        if (res.data.physiologyList && res.data.physiologyList.length == 1 && res.data.physiologyList.length != 0) {
          this.ageProgress = res.data.physiologyList[res.data.physiologyList.length - 1]
          this.peerTime = res.data.physiologyList[res.data.physiologyList.length - 1]
        } else {
          this.ageProgress = res.data.physiologyList[res.data.physiologyList.length - 1]
          this.peerTime = res.data.physiologyList[res.data.physiologyList.length - 1]
          this.lastTime = res.data.physiologyList[res.data.physiologyList.length - 2]
        }
        if (res.data.indicatorsList && res.data.indicatorsList.length > 0) {
          this.IndicatorData = res.data.indicatorsList
        }
      })

    },
    drawChart() {
      // 2. 基于准备好的dom，初始化echarts实例
      // 此处的意思就是，对 demo 元素 进行图表初始化的相关操作
      var myChart = this.$echarts.init(document.getElementById('barChart'))
      // 3. 指定图表的配置项和数据
      // 该处就是图表内容，在官网的示例里面，要复制过来到项目里面的也是这一块内容
      // const option = {
      //   title: {
      //     text: ''
      //   },
      //   tooltip: {},
      //   legend: { // 图例组件
      //     show: false, // 设置不显示图例
      //     data: ['销量']
      //   },
      //   xAxis: {
      //     data: [],
      //     axisTick: {
      //       show: false // 是否显示刻度线
      //     }

      //   },
      //   yAxis: {
      //     max: 4,
      //     axisLabel: {
      //       show: false
      //     },
      //     splitLine: {
      //       lineStyle: {
      //         color: 'rgba(159,174,208,0.1)'
      //       }
      //     }

      //   },
      //   series: [{
      //     barWidth: '8%',
      //     type: 'bar',
      //     data: [1, 2, 3],
      //     itemStyle: {
      //       color: function (params) {
      //       // 动态生成渐变色或者根据条件返回预设的渐变色
      //         const gradientColors = [
      //           {
      //             colorStops: [
      //               { offset: 0, color: 'red' }
      //             ]
      //           },
      //           {
      //             colorStops: [
      //               { offset: 0, color: 'blue' }
      //             ]
      //           },
      //           // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
      //           {
      //             colorStops: [
      //               {
      //                 offset: 0,
      //                 color: 'red' // 0% 处的颜色
      //               }
      //             ]
      //           }
      //         ]

      //         return gradientColors[params.dataIndex]
      //       }
      //     }
      //   }
      //   ]
      // }
      const option = this.barObj
      console.log('option', option)
      // option.xAxis.data = this.barObj.xAxis.data
      // 4.使用刚指定的配置项和数据显示图表。
      myChart.setOption(option)
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.achievement_pages {
  display: flex;

  .PersonHome_left_title {
    display: flex;
    align-items: center;
    // padding-top: 1.8vmax;

    .round {
      width: 0.8vmax;
      height: 0.8vmax;
      background: #18BFC8;
      border-radius: 50%;
    }

    .info {
      margin-left: 1vmax;
      padding: 0.2vmax 0.6vmax;
      background: #20D0D9;
      border-radius: 0.5vmax;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.6vmax;
      color: #FFFFFF;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-left: 0.6vmax;
    }
  }

  .bio_title {
    padding: 1vmax 0;
    padding-left: 1vmax;
    font-size: 1.4vmax;
  }

  .bio_content {
    padding: 0 1vmax;
    font-size: 1vmax;
  }

  .achievement_pages_title {
    position: relative;
    padding-left: 1.8em;
    font-size: 1.5vmax;
    color: #FFFFFF;
  }

  .achievement_pages_title::before {
    content: "";
    width: 0.8vmax;
    height: 0.8vmax;
    position: absolute;
    left: 1vmax;
    top: 0.7vmax;
    border-radius: 50%;
    background-color: #18BFC8;
  }

  .achievement_pages_left {
    margin-left: 1vmax;

    .achievement_pages_left_top {
      display: flex;
      box-sizing: border-box;
      padding: 1.3vmax 1.5vmax;
      width: 38vmax;
      height: 19.5vmax;
      background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
      border-radius: 1vmax;

      .transcript {
        display: flex;
        justify-content: space-between;
      }

      .achievement_pages_left_top_left {
        .achievement_pages_left_top_left_title {
          font-size: 1.6vmax;
          color: #FFFFFF;
          line-height: 1.9vmax;
          text-align: left;
          font-style: normal;
        }

        .achievement_pages_left_top_left_time {
          font-weight: 400;
          font-size: 0.8vmax;
          color: rgba(255, 255, 255, 0.6);
          line-height: 0.9vmax;
          text-align: left;
          font-style: normal;
        }

        .result {
          margin-top: 2vmax;
          width: 6vmax;
          height: 6vmax;
        }

        .sleep {
          padding-left: 3vmax;
          padding-top: 1vmax;

          .sleep_list {
            padding-top: 1vmax;

            .sleep_text {
              display: flex;
              justify-content: space-between;

              .sleep_text_left {
                color: #FFFFFF;
              }

              .sleep_text_right {
                color: #F9C716;
              }
            }

            .sleep_line {
              width: 10vmax;
              height: 0.4vmax;
              margin-top: 0.4vmax;
              background: #D9D9D9;
              border-radius: 1.4vmax;
              overflow: hidden;

              .sleep_line_item {
                height: 0.4vmax;
                background-color: #F9C716;
              }
            }
          }

          .sleep_list1 {
            padding-top: 1vmax;

            .sleep_text {
              display: flex;
              justify-content: space-between;

              .sleep_text_left {
                color: #FFFFFF;
              }

              .sleep_text_right {
                color: #5EC692;
              }
            }

            .sleep_line {
              width: 10vmax;
              height: 0.4vmax;
              margin-top: 0.4vmax;
              background: #D9D9D9;
              border-radius: 1.4vmax;
              overflow: hidden;

              .sleep_line_item {
                height: 0.4vmax;
                background-color: #5EC692;
              }
            }
          }

          .achievement_pages_left_top_rigth_Proportion {
            display: flex;
            padding-top: 2vmax;
            padding-left: 1vmax;

            .last_proportion {
              font-size: 1vmax;
              padding-left: 3vmax;
              padding-bottom: 3vmax;
            }
          }

          // .achievement_pages_left_top_rigth_Proportion {
          //   display: flex;
          //   padding-top: 2vmax;
          //   padding-left: 1vmax;

          //   .last_proportion {
          //     font-size: 1vmax;
          //     padding-left: 3vmax;
          //     padding-bottom: 3vmax;
          //   }
          // }
        }
      }


      .achievement_pages_left_top_rigth {
        width: 40vmax;
        height: 18vmax;



        .achievement_pages_left_top_rigth_img {
          width: 23vmax;
          height: 6vmax;
          margin-left: 2vmax;
          margin-right: 2vmax
        }
      }
    }

    .achievement_pages_left_bottom {
      box-sizing: border-box;
      padding: 1.5vmax;
      margin-top: 1vmax;
      width: 38vmax;
      height: 38.9vmax;
      background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
      border-radius: 1vmax;

      .achievement_pages_left_bottom_table {
        margin-top: 1vmax;

        .achievement_pages_left_bottom_table_tab {
          box-sizing: border-box;
          padding: 0 1vmax;
          display: flex;
          justify-content: space-between;
          margin-bottom: 1vmax;

          .achievement_pages_left_bottom_table_title {
            display: flex;
            justify-content: center;
            flex: 1;
            font-weight: 400;
            font-size: 1.6vmax;
            color: rgba(255, 255, 255, 0.6);
          }
        }

        .achievement_pages_left_bottom_table_content {
          height: 30.7vmax;
          overflow-y: auto;

          .achievement_pages_left_bottom_table_content_li {
            .achievement_pages_left_bottom_table_content_li_h {
              width: 100%;
              width: 38vmax;
              height: 0.1vmax;
              background: rgba(159, 174, 208, 0.1);
            }

            .achievement_pages_left_bottom_table_content_li_ul {
              padding: 0 1vmax;
              display: flex;
              justify-content: space-between;
              margin: 1vmax 0;

              .achievement_pages_left_bottom_table_content_li_ul_li {
                text-align: center;
                font-size: 1.2vmax;
                color: #FFFFFF;

                img {
                  width: 0.7vmax;
                  height: 1.5vmax;
                }

                .achievement_pages_left_bottom_table_content_li_ul_li_upD {
                  width: 1.5vmax;
                  height: 0.3vmax;
                  background-color: #fff;
                }

                .achievement_pages_left_bottom_table_content_li_ul_li_level {
                  width: 2.5vmax;
                  height: 2.5vmax;
                  background: #F9C716;
                  border-radius: 0.6vmax;
                  font-weight: 500;
                  font-size: 1.6vmax;
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }

    }
  }

  .achievement_pages_right {
    overflow-y: auto;
    box-sizing: border-box;
    padding: 1.5vmax;
    padding-right: 0;
    margin-left: 1vmax;
    width: 51vmax;
    height: 59.4vmax;
    background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
    border-radius: 1vmax;

    .tab {
      padding-top: 2vmax;
    }

    .physiological {
      padding-left: 2vmax;
      color: #FFFFFF;
      font-size: 1.4vmax;
      padding-top: 1vmax;
    }
  }
}
</style>
