<template>
    <div class="guide_map">
        <div class="PersonHome_left_title">
            <div class="round"></div>
            <div class="info">身体健康风险及诱发因素导览图</div>
        </div>
        <div class="guide_content">
            <div class=" guide_list_top">
                <div class="guide_box guide_family" @click="handleDetails(1, GuideMapData.familyData)">
                    <div class="guide_box_tilte">
                        <div class="guide_box_tilte_text">家族遗传史</div>
                        <div class="guide_box_tilte_icon">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240822/388FA10D9AAC44708DC92029D9745D09.png"
                                alt="">
                        </div>
                    </div>
                    <div class="guide_box_line"></div>
                    <div class="guide_box_content" v-if="GuideMapData.familyData">
                        <div v-for="(item, index) in GuideMapData.familyData" :key="index">
                            <div class="guide_box_list" v-if="index < 3">
                                <template>
                                    <div class="round"></div>
                                    <div class="text">{{ item.subUnBalanceTitle }}</div>
                                </template>
                            </div>
                        </div>
                        <div class="guide_box_list" v-if="GuideMapData.familyData.length > 3">
                            <div class="round"></div>
                            <div class="text">..........</div>
                        </div>
                    </div>
                </div>
                <div class="guide_box guide_life" @click="handleDetails(2, GuideMapData.lifeData)">
                    <div class="guide_box_tilte">
                        <div class="guide_box_tilte_text">生活方式</div>
                        <div class="guide_box_tilte_icon">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240822/388FA10D9AAC44708DC92029D9745D09.png"
                                alt="">
                        </div>
                    </div>
                    <div class="guide_box_line"></div>
                    <div class="guide_box_content" v-if="GuideMapData.lifeData">
                        <div v-for="(item, index) in GuideMapData.lifeData" :key="index">
                            <div class="guide_box_list" v-if="index < 3">
                                <template>
                                    <div class="round"></div>
                                    <div class="text">{{ item.lifeName }}</div>
                                </template>
                            </div>
                        </div>
                        <div class="guide_box_list" v-if="GuideMapData.lifeData.length > 3">
                            <div class="round"></div>
                            <div class="text">..........</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="guide_list_btn">
                <div class="guide_box guide_unbalance" @click="handleDetails(3, GuideMapData.unBalanceList)">
                    <div class="guide_box_tilte">
                        <div class="guide_box_tilte_text">失衡因素</div>
                        <div class="guide_box_tilte_icon">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240822/388FA10D9AAC44708DC92029D9745D09.png"
                                alt="">
                        </div>
                    </div>
                    <div class="guide_box_line"></div>
                    <div class="guide_box_content" v-if="GuideMapData.unBalanceList">
                        <div v-for="(item, index) in GuideMapData.unBalanceList" :key="index">
                            <div class="guide_box_list" v-if="index < 3">
                                <template>
                                    <div class="round"></div>
                                    <div class="text">{{ item.unBalanceTitle }}</div>
                                </template>
                            </div>
                        </div>
                        <div class="guide_box_list" v-if="GuideMapData.unBalanceList.length > 3">
                            <div class="round"></div>
                            <div class="text">..........</div>
                        </div>
                    </div>
                </div>
                <div class="guide_box guide_risk"  @click="handleDetails(4, GuideMapData.organList)">
                    <div class="guide_box_tilte">
                        <div class="guide_box_tilte_text">全身器官健康分析</div>
                        <div class="guide_box_tilte_icon">
                            <img class="wh100"
                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240822/388FA10D9AAC44708DC92029D9745D09.png"
                                alt="">
                        </div>
                    </div>
                    <div class="guide_box_line"></div>
                    <div class="guide_box_content" v-if="GuideMapData.organList">
                        <div v-for="(item, index) in GuideMapData.organList" :key="index">
                            <div class="guide_box_list" v-if="index < 3">
                                <template>
                                    <div class="round"></div>
                                    <div class="text">{{ item.typeName }}</div>
                                </template>
                            </div>
                        </div>
                        <div class="guide_box_list" v-if="GuideMapData.organList.length > 3">
                            <div class="round"></div>
                            <div class="text">..........</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Model :ModalBase="ModalBase" @handleClose="handleClose" @changeInside="handleClose"
            :animationState="animationState" v-if="isShowModel">
            <template v-slot:content>
                <div class="model_content">
                    <template v-if="currentType == 1 || currentType == 2">
                        <div class="model_content_title">
                            {{ showContent.title }}
                        </div>
                        <div class="model_content_line"></div>
                        <div class="guide_box_list" v-for="(item, index) in showContent.list">
                            <div class="round"></div>
                            <div class="text">{{ item }}</div>
                        </div>
                    </template>
                    <template v-if="currentType == 3">
                        <div class="model_content_title">
                            {{ showContent.title }}
                        </div>
                        <div class="model_content_line"></div>
                        <div class="factors">
                            <div class="factors_item" v-for="(item, index) in showContent.list">
                                <div class="factors_item_title">{{ item.unBalanceTitle }}</div>
                                <div class="factors_item_line"></div>
                                <div class="factors_item_text" v-for="(item1, index) in item.balanceDetail">
                                    <div class="round"></div>
                                    <div class="text">{{ item1.showName }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                    <template v-if="currentType == 4">
                        <div class="model_content_title">
                            {{ showContent.title }}
                        </div>
                        <div class="model_content_line"></div>
                        <div class="risk">
                            <div class="risk_item" v-for="(item, index) in showContent.list">
                                {{ item.typeName }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </Model>
    </div>
</template>
<script>
import Model from '/src/views/userInformation/components/model.vue'
export default {
    name: 'GuideMapData',
    components: {
        Model,
    },
    data() {
        return {
            currentType: null,
            isShowModel: false,
            animationState: true,
            showContent: {
                title: '',
                list: []
            },
            ModalBase: {
                boxHight: '38vmax',
                boxWidth: '38vmax',
                boxBgc: '#2B2C3D'
            },
        }
    },
    props: ['GuideMapData'],
    methods: {
        handleDetails(type, data) {
            this.currentType = type
            // if ((!data || data.length < 4) && (type == 1 || type == 2)) return
            this.showContent.list = []
            switch (type) {
                case 1:
                    this.showContent.title = '家族遗传史'
                    this.ModalBase.boxWidth = '38vmax'
                    data.map(v => {
                        this.showContent.list.push(v.subUnBalanceTitle)
                    })
                    break;
                case 2:
                    this.showContent.title = '生活方式'
                    this.ModalBase.boxWidth = '38vmax'
                    data.map(v => {
                        this.showContent.list.push(v.lifeName)
                    })
                    break;
                case 3:
                    this.showContent.title = '失衡因素'
                    this.showContent.list = data
                    this.ModalBase.boxWidth = '48vmax'
                    break;
                case 4:
                    this.showContent.title = '全身器官健康分析'
                    this.showContent.list = data
                    this.ModalBase.boxWidth = '48vmax'
                    console.log('this.showContent.list', this.showContent.list);

                    break;
                default:
                    break;
            }
            this.isShowModel = true
        },
        handleClose() {
            // 关闭弹窗--动画重置
            this.animationState = false
            setTimeout(() => {
                this.isShowModel = false
                this.animationState = true
            }, 400)
        },
    },
}

</script>
<style lang="scss" scoped>
.model_content {
    overflow-y: auto;

    .model_content_title {
        text-align: center;
        font-size: 1.6vmax;
    }

    .model_content_line {
        width: 100%;
        height: 1px;
        background-color: #FFFFFF;
        margin-bottom: 0.6vmax;
    }

    .risk {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 1vmax;
        color: #FFFFFF;

        .risk_item {
            width: 20vmax;
            height: 3vmax;
            border-radius: 1vmax;
            text-align: center;
            font-size: 1.4vmax;
            line-height: 3vmax;
            background-color: #323344;
            margin-top: 1.4vmax;
        }
    }

    .factors {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        color: #FFFFFF;
        padding: 0 1vmax;
        height: 38vmax;
        overflow-y: scroll;

        .factors_item {
            width: 20vmax;
            height: 18vmax;
            border-radius: 1vmax;
            background-color: #323344;
            margin-top: 1.4vmax;

            .factors_item_line {
                width: 100%;
                height: 1px;
                background-color: #FFFFFF;
                margin-bottom: 0.6vmax;
            }

            .factors_item_title {
                font-size: 1.4vmax;
                text-align: center;
                line-height: 3vmax;
            }

            .factors_item_text {
                display: flex;
                align-items: center;
                font-size: 1.2vmax;
                padding: 0.6vmax 1vmax;

                .round {
                    width: 0.4vmax;
                    height: 0.4vmax;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                }

                .text {
                    padding-left: 0.4vmax;
                }
            }
        }
    }

    .guide_box_list {
        display: flex;
        align-items: center;
        font-size: 1.2vmax;
        padding-left: 1vmax;
        padding-top: 0.4vmax;

        .round {
            width: 0.4vmax;
            height: 0.4vmax;
            background-color: #FFFFFF;
            border-radius: 50%;
        }

        .text {
            padding-left: 0.4vmax;
        }
    }
}

.guide_map {

    .PersonHome_left_title {
        padding-left: 2.5vmax;
        display: flex;
        align-items: center;
        padding-top: 1.8vmax;

        .round {
            width: 0.8vmax;
            height: 0.8vmax;
            background: #18BFC8;
            border-radius: 50%;
        }

        .info {
            margin-left: 1vmax;
                padding: 0.2vmax 0.6vmax;
                background: #20D0D9;
                border-radius: 0.5vmax;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 1.6vmax;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
                text-transform: none;
                padding-left: 0.6vmax;
        }
    }

    .guide_content {
        position: relative;
        width: 19.8vmax;
        height: 29vmax;
        margin: 0 auto;
        margin-top: 14vmax;
        background-image: url('https://image.giantgocloud.com/www/ImageMapping/image/20240822/EDAD2922C4904335A28B8D0D341F073E.png');
        background-size: 100%;
        background-repeat: no-repeat;

        .guide_list_top {
            display: flex;

            .guide_family {
                position: absolute;
                left: -9vmax;
                top: -13vmax;
            }

            .guide_life {
                position: absolute;
                left: 13.5vmax;
                top: -13vmax;
            }
        }

        .guide_box {
            overflow-y: auto;
            width: 15.2vmax;
            height: 12.2vmax;
            background: #38394D;
            border-radius: 2vmax;
            padding-top: 1vmax;

            .guide_box_tilte {
                display: flex;
                justify-content: center;
                align-items: center;

                .guide_box_tilte_text {
                    font-size: 1.4vmax;
                }

                .guide_box_tilte_icon {
                    width: 0.8vmax;
                    height: 1.3vmax;
                    margin-top: 0.5vmax;
                    margin-left: 0.2vmax;
                }
            }

            .guide_box_line {
                width: 100%;
                height: 1px;
                margin-top: 0.2vmax;
                border: 1px solid;
                border-image: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1), rgba(255, 255, 255, 0)) 1 1;
            }

            .guide_box_content {
                .guide_box_list {
                    display: flex;
                    align-items: center;
                    font-size: 1.2vmax;
                    padding-left: 1vmax;
                    padding-top: 0.4vmax;

                    .round {
                        width: 0.4vmax;
                        height: 0.4vmax;
                        background-color: #FFFFFF;
                        border-radius: 50%;
                    }

                    .text {
                        padding-left: 0.4vmax;
                    }
                }
            }
        }

        .guide_list_btn {
            display: flex;

            .guide_unbalance {
                position: absolute;
                position: absolute;
                left: -9vmax;
                top: 28vmax;
            }

            .guide_risk {
                position: absolute;
                left: 13.5vmax;
                top: 28vmax;
            }
        }
    }
}
</style>