<template>
  <div>
    <div  class="pieZ_box a_flex" style="position: relative;" :style="'width:'+widthA+'vmax;height:'+widthA+'vmax;'">
      <canvas id="bbcanvas" :width="this.pageWidth / 1000*250" :height="this.pageWidth / 1000*250">
        您的浏览器不支持html5的canvas元素
      </canvas>
     <img class="pieZ_box_img"
        src="https://image.giantgocloud.com/www/ImageMapping/image/20240705/775FE0426B1D4F4EA4EADBFF6AC79F4E.png"
        alt="" />
        <!-- <div style="position: absolute;left: 50px;top: 50px;">1231</div> -->
    </div>
  </div>

</template>

<script>
export default {
  props: {
    widthA: {
      type: String, // 类型
      required: false, // 是否必填
      default: '25' // 默认值
    },
    dataList: {
      type: Array, // 类型
      required: false // 是否必填
    }
  },

  watch: {
    widthA: {
      handler: function (newV, oldV) {
        console.log('newV', newV)
      },
      deep: true,
      immediate: true
    },
    dataList: {
      handler: function (newV, oldV) {
        this.dataListA = []
        this.pageWidth = window.innerWidth
        this.ifShowA = false
        console.log('dataList', newV)
        if (!newV) {
          return
        }
        const fWX = 2
        this.dataListLength = newV.length
        this.pieL = (360 - this.dataListLength * fWX) / this.dataListLength
        console.log('this.pieL', this.pieL)
        for (let i = 0; i < newV.length; i++) {
          this.dataListA.push(newV[i])
          if (i == 0) {
            this.dataListA[i].rotate = 0
          } else {
            let alRotate = fWX + this.pieL
            // if (alRotate > 360) {
            //   alRotate = this.xxx(alRotate)
            // }
            console.log('alRotate', alRotate)
            console.log('this.dataListA[q].rotate', this.dataListA[this.dataListA.length - 2].rotate)
            alRotate = alRotate + this.dataListA[this.dataListA.length - 2].rotate
            this.dataListA[i].rotate = alRotate
          }
        }
        console.log('this.dataListA', this.dataListA)
        let centerXY = 100
        const startPie = 180
        const bl = this.pageWidth / 1000
        setTimeout(() => {
          centerXY = (this.widthA * 10 / 2) * bl
          this.ifShowA = true
        }, 10)
        setTimeout(() => {
          var bbcanvas = document.getElementById('bbcanvas')
          this.ctxb = bbcanvas.getContext('2d')
          console.log('bbcanvas.width', bbcanvas.width)
          this.ctxb.clearRect(-500, -500, 1000, 1000)
          this.ctxb.lineWidth = 0
          if (!this.numxx) {
            this.numxx++
            console.log('numxxaA', this.numxx)
            this.ctxb.translate(centerXY, centerXY)
          }
          this.ctxb.save()
          for (let i = 0; i < this.dataListA.length; i++) {
            this.ctxb.restore()
            if (this.dataListA[i].colorCode) {
              this.ctxb.fillStyle = this.dataListA[i].colorCode
            } else {
              this.ctxb.fillStyle = '#b3b4b4'
            }
            this.ctxb.beginPath()
            this.ctxb.moveTo(0, 0)
            this.ctxb.arc(0, 0, 125 * bl, (this.dataListA[i].rotate + fWX) * Math.PI / 180, (this.dataListA[i]
              .rotate +
                fWX + this.pieL) * Math.PI / 180)
            this.ctxb.fill()
          }

          this.ctxb.restore()
          this.ctxb.fillStyle = '#36384d'
          this.ctxb.beginPath()
          this.ctxb.moveTo(0, 0)
          this.ctxb.arc(0, 0, 110 * bl, 0 * Math.PI / 180, 360 * Math.PI / 180)
          this.ctxb.fill()
          this.ctxb.closePath()
          for (let i = 0; i < this.dataListA.length; i++) {
            this.ctxb.restore()
            this.ctxb.fillStyle = '#292a3f'
            this.ctxb.beginPath()
            this.ctxb.moveTo(0, 0)
            // this.ctxb.fillRect(-(125 * bl) / 2, -5 / 2, (125 * bl), 5)
            this.ctxb.arc(0, 0, 125 * bl, this.dataListA[i].rotate * Math.PI / 180, (this.dataListA[i].rotate +
                  fWX) *
                Math.PI / 180)
            this.ctxb.fill()
            this.ctxb.closePath()
          }
          this.ctxb.closePath()
          let stateA = 0
          for (let i = 0; i < this.dataListA.length; i++) {
            this.ctxb.restore()
            this.ctxb.save()
            var text = this.dataListA[i].indicatorEnName
            var x = 80 * bl
            var y = 0
            var font = '12px Arial'
            const textMetrics = this.ctxb.measureText(text)
            console.log('textMetrics', textMetrics)
            this.ctxb.translate(-textMetrics.width / 4, 0)
            this.ctxb.font = font
            this.ctxb.fillStyle = '#fff' // 定义字体颜色;
            this.dataListA[i].rotate + fWX
            stateA = this.pieL + 2 + stateA
            this.ctxb.rotate(((this.pieL + 2 * (i * 0.8)) / 2 + (this.pieL / 2) * i) * 2 * Math.PI / 180)
            console.log('x', x)
            console.log('x + 15', x + 15)
            console.log('this.pieL', this.pieL)
            let xnum = 300
            if (this.dataListLength == 2) {
              xnum = 300
            } else {
              xnum = 160
            }
            this.ctxb.translate(x + 15 - (xnum / this.dataListLength * 4 / 10), -this.pieL + (320 / this.dataListLength * 7 / 10))
            this.ctxb.rotate(-((this.pieL + 2 * (i * 0.8)) / 2 + (this.pieL / 2) * i) * 2 * Math.PI / 180)
            this.ctxb.fillText(text, 0, 0)
            this.ctxb.restore()
            this.ctxb.closePath()
          }
          this.ctxb.restore()
          this.ctxb.fillStyle = '#2a2b40'
          this.ctxb.beginPath()
          this.ctxb.moveTo(0, 0)
          this.ctxb.arc(0, 0, 60 * bl, 0 * Math.PI / 180, 360 * Math.PI / 180)
          this.ctxb.fill()
          this.ctxb.closePath()
          console.log('this.dataListA', this.dataListA)
        }, 500)
      },
      deep: true,
      immediate: true
    }
  },
  data () {
    return {
      numxx: 0,
      ctxb: '',
      ifShowA: false,
      pageWidth: 500,
      pieL: 30,
      dataListA: [],
      dataListLength: 1
      // dataList: [{
      //   colorCode: 'red',
      //   indicatorEnName: 'aaa'
      // },
      // {
      //   colorCode: '#5ec692',
      //   indicatorEnName: 'bbb'
      // },
      // {
      //   colorCode: 'red',
      //   indicatorEnName: 'ccc'
      // },
      // {
      //   colorCode: '#5ec692',
      //   indicatorEnName: 'ddd'
      // },
      // {
      //   colorCode: 'red',
      //   indicatorEnName: 'eee'
      // }
      // ]
    }
  },
  beforeDestroy () {
    this.ctxb.clearRect(-500, -500, 1000, 1000)
  },
  methods: {
    rotateText (angle) {

    },
    drawRotatedRect (ctx, x, y, width, height, angle) {
      // 保存当前状态
      ctx.save()

      // 平移到矩形中心
      ctx.translate(x, y)
      // 旋转矩形
      ctx.rotate(angle * Math.PI / 180)
      // 画出未旋转的矩形
      ctx.fillRect(-width / 2, -height / 2, width, height)

      // 恢复状态
      ctx.restore()
    },
    xxx (alRotate) {
      const rr = alRotate - 360
      if (rr > 360) {
        return this.xxx(rr)
      } else {
        return rr
      }
    }
  }
}
</script>

<style lang='scss' scoped>
  .pieZ_box {
    position: relative;

    .pieZ_box_img {
      position: absolute;
      width: 10vmax;
      height: 10vmax;
      top: 50%;
      left: 50%;
      transform: translate(-5.4vmax, -5vmax);
      z-index: 100;
    }

    .main {
      height: 100%;
      background: #343546;
      /* border-radius: 100px; */
    }

    .common {
      position: absolute;
      top: 0;
      width: 50%;
      height: 100%;
    }

    .mask1 {
      transform: rotate(0deg);
      border-radius: 100px 0 0 100px;
      left: 0;
      transform-origin: right center;
      background: red;
    }

    /* 	.mask2 {
    			transform: rotate(-76deg);
    			transform-origin: left center;
    			left: 100px;
    			border-radius: 0 100px 100px 0;
    			background: blue;
    		} */

  }
</style>
